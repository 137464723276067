<div class="list-content" [class.loading]="loading()" [class.borderless]="borderless()">
  @if (!headerless()) {
    <header>
      @if (collectionTitle()) {
        <h2>
          {{ collectionTitle() }} <span><ng-content select="[collectionListTitleBadge]" /></span>
        </h2>
      }
      <div class="primary-action">
        <ng-content select="[collectionListPrimaryAction]" />
      </div>
      <div class="search" data-test-id="searchInput">
        <ng-content select="[collectionListSearch]" />
      </div>
      <div class="table-actions">
        <ng-content select="[collectionListActions]" />
      </div>
    </header>
  }

  <section class="list-body">
    <div class="table">
      <ng-content select="[collectionListTable]" />
    </div>

    <div class="empty-state">
      <ng-content select="ciphr-empty-collection" />
    </div>
  </section>

  <section class="paging" data-test-id="paging">
    <ng-content select="ciphr-paging, [collectionPagination]" />
  </section>
</div>

<section>
  <ng-content select="[collectionSideContent]" />
</section>

@if (loading()) {
  <div class="loader">
    <chr-spinner palette="accent" size="m" />
  </div>
}
