import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, inject, input, model } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FormsModule } from '@angular/forms';

import { toSignal } from '@angular/core/rxjs-interop';

import { map } from 'rxjs';

import { TranslocoDirective } from '@jsverse/transloco';

import { ChrComponentsModule } from '@ciphr-design-system/angular';

import { Paging } from '@ciphr/utils/collections';

import { PagingInfoPipe } from './paging-info.pipe';

@Component({
  selector: 'ciphr-paging',
  standalone: true,
  imports: [FormsModule, TranslocoDirective, ChrComponentsModule, PagingInfoPipe],
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagingComponent {
  private breakpointObserver = inject(BreakpointObserver);

  compactMode = input(false, { transform: coerceBooleanProperty });
  paging = model.required<Paging>();
  limitedPageSize = input<Paging['pageSize']>(500);

  mobileMode = toSignal(this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet]).pipe(map((state) => state.matches)), {
    initialValue: false,
  });

  readonly pageSizes = [10, 20, 50, 100, 200, 300, 500];

  calculatePages({ pageSize, totalLength }: Paging): number {
    return Math.ceil(totalLength / pageSize);
  }

  changePageIndex({ detail }: CustomEvent<number>): void {
    this.paging.update((paging) => ({ ...paging, pageIndex: detail }));
  }

  changePageSize(pageSize: string): void {
    this.paging.update((paging) => ({ ...paging, pageSize: +pageSize, pageIndex: 1 }));
  }
}
