@if (paging(); as paging) {
  <div class="pagination-section" *transloco="let t">
    <div class="results" aria-live="polite">{{ paging | pagingInfo }}</div>

    @if (paging.totalLength) {
      <div class="pages">
        <chr-pagination
          [compact]="compactMode() || mobileMode()"
          [current]="paging.pageIndex"
          [total]="calculatePages(paging)"
          (chr-page-selected)="changePageIndex($event)"
        />
      </div>
    }

    @if (!(compactMode() || mobileMode())) {
      <div class="page-size">
        <chr-select
          size="s"
          [ngModel]="paging.pageSize.toString()"
          (ngModelChange)="changePageSize($event)"
          [attr.aria-label]="t('COMMON.COLLECTIONS.ARIA.SELECT.PAGE_SIZE')"
        >
          @for (page of pageSizes; track page) {
            @if (page <= limitedPageSize()) {
              <option [value]="page">{{ page }}</option>
            }
          }
        </chr-select>

        <span class="results">{{ t("COMMON.COLLECTIONS.PAGING.PAGE_SIZE_LABEL") }}</span>
      </div>
    }
  </div>
}
