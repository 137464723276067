import { signalStore, withMethods, withState } from '@ngrx/signals';

import { ActiveCollectionFilter } from '../models/active-collection-filter.type';
import { CollectionFilter } from '../models/collection-filter.type';
import { collectionFilteringDataAdapterMethodsFactory } from './collection-filtering.data-adapter-methods';
import { collectionFilteringReductionMethodsFactory } from './collection-filtering.reduction-methods';

export type CollectionFilteringState = {
  activeFilters: ActiveCollectionFilter[];
  availableFilters: CollectionFilter[];
  drawerOpened: boolean;
  loading: boolean;
};

const initialState: CollectionFilteringState = {
  activeFilters: [],
  availableFilters: [],
  drawerOpened: false,
  loading: false,
};

export const CollectionFilteringStore = signalStore(
  { protectedState: false },
  withState(initialState),
  withMethods(collectionFilteringDataAdapterMethodsFactory),
  withMethods(collectionFilteringReductionMethodsFactory),
);
