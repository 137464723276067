import { ChangeDetectionStrategy, Component, EventEmitter, input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

import { ChrComponentsModule } from '@ciphr-design-system/angular';

import { Ordering } from '@ciphr/utils/collections';

@Component({
  selector: 'ciphr-table-column-header',
  standalone: true,
  imports: [NgClass, ChrComponentsModule],
  templateUrl: './table-column-header.component.html',
  styleUrls: ['./table-column-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnHeaderComponent {
  currentOrdering = input<Ordering>();
  orderingProperty = input<string>();

  @Output() sort = new EventEmitter<Ordering>();

  get active(): boolean {
    return this.orderingProperty() === this.currentOrdering()?.property;
  }

  get ascend(): boolean {
    return this.active && this.currentOrdering()?.direction === 'asc';
  }

  get descend(): boolean {
    return this.active && this.currentOrdering()?.direction === 'desc';
  }

  get headerClasses(): { [klass: string]: boolean } {
    return {
      active: this.active,
      sortable: !!this.orderingProperty,
    };
  }

  get tabIndex(): number {
    return this.orderingProperty() ? 0 : -1;
  }

  emitOrdering(): void {
    const property = this.orderingProperty();

    property &&
      this.sort.emit({
        direction: this.active && this.currentOrdering()?.direction === 'asc' ? 'desc' : 'asc',
        property,
      });
  }
}
