import { inject, Pipe, PipeTransform } from '@angular/core';

import { TranslocoService } from '@jsverse/transloco';

import { Paging } from '@ciphr/utils/collections';

@Pipe({
  name: 'pagingInfo',
  standalone: true,
})
export class PagingInfoPipe implements PipeTransform {
  private translocoService = inject(TranslocoService);

  transform({ pageIndex, pageSize, totalLength }: Paging): string {
    const pageRange = `${pageSize * (pageIndex - 1) + 1} - ${pageSize * pageIndex > totalLength ? totalLength : pageSize * pageIndex}`;
    const rangeInfo = this.translocoService.translate('COMMON.COLLECTIONS.PAGING.RANGE_INFO', { pageRange, totalLength });

    return this.translocoService.translate('COMMON.COLLECTIONS.PAGING.RESULTS_INFO', { rangeInfo: totalLength > 0 ? rangeInfo : '0' });
  }
}
