import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, input } from '@angular/core';

import { ChrComponentsModule } from '@ciphr-design-system/angular';

@Component({
  selector: 'ciphr-collection-list',
  standalone: true,
  imports: [ChrComponentsModule],
  templateUrl: './collection-list.component.html',
  styleUrls: ['./collection-list.component.scss'],
})
export class CollectionListComponent {
  borderless = input(false, { transform: coerceBooleanProperty });
  collectionTitle = input<string>();
  headerless = input(false, { transform: coerceBooleanProperty });
  loading = input(false);
}
