import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { EmptyCollectionStates } from '@ciphr/utils/collections';

import { NoRecordsComponent, NoResultComponent } from '@ciphr/ui';

@Component({
  selector: 'ciphr-empty-collection',
  standalone: true,
  imports: [NoRecordsComponent, NoResultComponent],
  templateUrl: './empty-collection.component.html',
  styles: [':host { display: block; width: 100%; height: 100%; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyCollectionComponent {
  state = input.required<EmptyCollectionStates | null>();

  readonly emptyCollectionStates = EmptyCollectionStates;
}
