import { inject } from '@angular/core';

import { exhaustMap, pipe, tap } from 'rxjs';

import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { patchState, WritableStateSource } from '@ngrx/signals';
import { tapResponse } from '@ngrx/operators';

import { CollectionFilteringState } from './collection-filtering.store';
import { COLLECTION_FILTERING_DATA_ADAPTER } from '../data-adapters/collection-filtering-data-adapter.token';

export const collectionFilteringDataAdapterMethodsFactory = (
  state: WritableStateSource<CollectionFilteringState>,
  dataAdapter = inject(COLLECTION_FILTERING_DATA_ADAPTER),
) => ({
  loadFilters: rxMethod<unknown[] | void>(
    pipe(
      tap(() => patchState(state, { loading: true })),
      exhaustMap((params) =>
        dataAdapter.fetchFilters(...(params || [])).pipe(
          tapResponse({
            next: (filters) => patchState(state, { availableFilters: filters, loading: false }),
            error: () => patchState(state, { loading: false }),
          }),
        ),
      ),
    ),
  ),
});
