import { patchState, WritableStateSource } from '@ngrx/signals';

import { ActiveCollectionFilter } from '../models/active-collection-filter.type';
import { CollectionFilteringState } from './collection-filtering.store';

export const collectionFilteringReductionMethodsFactory = (state: WritableStateSource<CollectionFilteringState>) => ({
  closeDrawer(): void {
    patchState(state, { drawerOpened: false });
  },
  clearFilters(): void {
    patchState(state, { activeFilters: [], drawerOpened: false });
  },
  setFilters(activeFilters: ActiveCollectionFilter[]): void {
    patchState(state, { activeFilters, drawerOpened: false });
  },
  toggleDrawer(): void {
    patchState(state, (state) => ({ drawerOpened: !state.drawerOpened }));
  },
});
