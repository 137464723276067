import { Directive } from '@angular/core';
import { TippyDirective } from '@ngneat/helipopper';

@Directive({
  selector: '[ciphrTooltip]',
  standalone: true,
  hostDirectives: [
    {
      directive: TippyDirective,
      inputs: ['tp: message', 'tpOnlyTextOverflow: onlyTextOverflow', 'tpIsEnabled: isEnabled'],
    },
  ],
})
export class TooltipDirective {
  constructor(tooltipDirective: TippyDirective) {
    tooltipDirective.onlyTextOverflow = true;
    tooltipDirective.isLazy = true;
  }
}
